import React from "react";
import "./portfolioButtons.css";

export default function PortfolioButtons(props) {
  return (
    <div>
      <div className="portfolio_btns">
        <button
          onClick={() => window.open(props.github, "_blank")}
          target="_blank"
          className="portfolio_btns_code"
        >
          Code
        </button>
        <button
          onClick={() => window.open(props.demo, "_blank")}
          target="_blank"
          className="portfolio_btns_demo"
        >
          Demo
        </button>
      </div>
    </div>
  );
}
