import React, { useState } from "react";
import { useRouteMatch, NavLink, Link } from "react-router-dom";
import { FaTwitter, FaGithub, FaInstagram } from "react-icons/fa";
// Components
import DemoOptions from "../../components/common/DemoOptions";

const links = [
  {
    title: "Home",
    path: "/intro",
  },
  {
    title: "About",
    path: "/about",
  },
  {
    title: "Resume",
    path: "/resume",
  },
  // {
  //   title: "Service",
  //   path: "/service",
  // },
  {
    title: "Portfolio",
    path: "/portfolio",
  },
  // {
  //   title: "Blog",
  //   path: "/blog",
  // },
  // {
  //   title: "Testimonial",
  //   path: "/testimonial",
  // },
  {
    title: "Contact",
    path: "/contact",
  },
];

function Header() {
  let { url } = useRouteMatch();

  const [displaySidenav, setDisplaySidenav] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setDisplaySidenav(!displaySidenav);
  };

  return (
    <>
      <header className="app-header app-header-1">
        <div className="header-logo">
          {/* original line */}
          {/* <Link to={`url`.replace(/([^:])(\/\/+)/g, "$1/")}>{`<RE/>`}</Link> */}
          <Link to={"//intro"}>{`<RE/>`}</Link>
        </div>
        <button className="button button-menu" onClick={(e) => handleClick(e)}>
          <span></span>
        </button>
        <ul className="header-dots">
          {links.map((item, index) => (
            <li key={index}>
              <NavLink to={`${url}${item.path}`} activeClassName="active">
                <span className="circle"></span>
              </NavLink>
            </li>
          ))}
        </ul>
        <ul className="header-menu">
          {links.map((item, index) => (
            <li key={index}>
              <NavLink to={`${url}${item.path}`} activeClassName="active">
                <span className="anchor">{item.title}</span>
              </NavLink>
            </li>
          ))}
        </ul>
        <div className="header-follow">
          <ul>
            <li>
              <a target="_blank" href="https://github.com/ryanevans253">
                <i className="ti-github"></i>
              </a>
            </li>
            <li>
              <a href="mailto:ryan@ryanevans.co">
                <i className="ti-email"></i>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/in/ryanwilliamevans/"
              >
                <i className="ti-linkedin"></i>
              </a>
            </li>
          </ul>
          <p>Connect</p>
        </div>
      </header>
      <div className={`app-sidenav ${displaySidenav && "active"}`}>
        <div className="sidenav-menu">
          <div className="logo">
            <Link to={`url`.replace(/([^:])(\/\/+)/g, "$1/")}>{`<RE/>`}</Link>
          </div>
          <button className="button" onClick={(e) => handleClick(e)}></button>
          <ul className="sidenav-nav">
            {links.map((item, index) => (
              <li key={index} onClick={(e) => handleClick(e)}>
                <NavLink
                  to={`${url}${item.path}`.replace(/([^:])(\/\/+)/g, "$1/")}
                  activeClassName="active"
                >
                  <span className="anchor">{item.title}</span>
                </NavLink>
              </li>
            ))}
          </ul>
          <div className="sidenav-links">
            <ul>
              <li>
                <a target="_blank" href="https://github.com/ryanevans253">
                  <i className="ti-github"></i>
                </a>
              </li>
              <li>
                <a href="mailto:ryan@ryanevans.co">
                  <i className="ti-email"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/ryanwilliamevans/"
                >
                  <i className="ti-linkedin"></i>
                </a>
              </li>
            </ul>
            <p>Connect With Me</p>
          </div>
        </div>
        <span className="sidenav-close" />
      </div>
      {/* <DemoOptions /> */}
    </>
  );
}

export default Header;
