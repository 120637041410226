import React from "react";
import TextLoop from "react-text-loop";
import { Container } from "react-grid-system";
import { Link } from "react-router-dom";

function Intro() {
  return (
    <section
      className="section section-hero section-hero-1 overlay-image"
      style={{ backgroundImage: `url(/assets/images/hero/forest.jpg)` }}
    >
      <div className="display-center">
        <Container>
          <div className="el-heading">
            <p className="el-icon">
              <span className="el-icon-title">I'm Ryan Evans.</span>
            </p>
            <h1>
              I'm a{" "}
              <TextLoop>
                <div> Software Developer</div>
                <span>Real Estate Investor</span>
                <span>Business Consultant</span>
              </TextLoop>
              <br />
              Based in Seattle
            </h1>
            <Link to="//portfolio">
              <button className="button button-lg button-primary">
                <span className="wave"></span>
                <span className="text">My Portfolio</span>
              </button>
            </Link>
            <Link to="//about">
              <button className="button button-lg">
                <span className="text">Read More</span>
              </button>
            </Link>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Intro;
