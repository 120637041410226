import React from "react";
import { Container, Hidden, Row, Col } from "react-grid-system";

function Contact() {
  return (
    <section className="section section-contact section-contact-1">
      <div className="display-spacing">
        <Container>
          <Row>
            <Hidden xs sm md>
              <Col lg={5}>
                <div className="contact-image">
                  <div
                    className="image overlay-image"
                    style={{
                      backgroundImage: `url(/assets/images/contact/callryan.jpg)`,
                    }}
                  ></div>
                </div>
              </Col>
            </Hidden>
            <Col lg={7}>
              <div className="contact-content">
                <header className="el-heading">
                  <h2 className="el-icon">
                    <span className="el-icon-title">CONTACT ME</span>
                  </h2>
                  <h3>
                    <span>Get in touch with me today.</span>
                  </h3>
                  <div className="divider divider-1"></div>
                  <p>
                    I'd love to chat with you about working with your team or
                    helping on your next project. Give me a call, send me an
                    email, or reach out on LinkedIn!
                  </p>
                  <p>+1.253.257.0082</p>
                </header>
                <div className="sidenav-links">
                  <a target="_blank" href="https://github.com/ryanevans253">
                    <i className="ti-github" style={{ margin: "5px" }}></i>
                  </a>
                  <a href="mailto:ryan@ryanevans.co">
                    <i style={{ margin: "5px" }} className="ti-email"></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/in/ryanwilliamevans/"
                  >
                    <i style={{ margin: "5px" }} className="ti-linkedin"></i>
                  </a>
                </div>

                <form className="form form-1 pt-15">
                  <Row className="row">
                    <Col xs={12} sm={12} md={6}>
                      <div className="form-item">
                        <input type="text" id="form-item-name" />
                        <label htmlFor="form-item-name">Your Name</label>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                      <div className="form-item">
                        <input type="email" id="form-item-email" />
                        <label htmlFor="form-item-email">Your Email</label>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                      <div className="form-item">
                        <textarea id="form-item-message"></textarea>
                        <label htmlFor="form-item-message">Your Message</label>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                      <button className="button button-md button-primary">
                        Send Message
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Contact;
