import React from "react";
import { Container, Row, Col } from "react-grid-system";
// Components
import ProgressBar from "../../components/common/ProgressBar";

const skills = [
  {
    title: "JavaScript",
    percent: 100,
  },
  {
    title: "React.JS",
    percent: 100,
  },
  {
    title: "Express.js",
    percent: 100,
  },
  {
    title: "MongoDB",
    percent: 100,
  },
  {
    title: "HTML",
    percent: 100,
  },
  {
    title: "CSS",
    percent: 100,
  },
  {
    title: "Node.js",
    percent: 100,
  },
  {
    title: "REST API",
    percent: 100,
  },
];
// const education = [
//   {
//     title: "Front-End Developer",
//     subtitle: "Freelance | Cleveland, Ohio, USA",
//     description:
//       "Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo.",
//     date: "(2020-Current)",
//     icon: "ti-pencil-alt",
//   },
//   {
//     title: "Real Estate Investor",
//     subtitle: "D & E Homes | Cleveland, Ohio, USA",
//     description:
//       "Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo.",
//     date: "(2017-Current)",
//     icon: "ti-heart",
//   },
//   {
//     title: "Director",
//     subtitle: "X-Team | Melbourne, Victoria, Australia",
//     description:
//       "Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo.",
//     date: "(2016-2018)",
//     icon: "ti-paint-bucket",
//   },
//   {
//     title: "Bachelors in Business Management",
//     subtitle: "Western Washington University | Bellingham, WA",
//     description:
//       "Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo.",
//     date: "(2016-2018)",
//     icon: "ti-crown",
//   },
// ];
const experience = [
  {
    title: "Front-End Developer",
    subtitle: "Freelance | Seattle, WA, USA",
    description:
      "Designs and builds front-end web applications and user interfaces with the tools over there! >>>",
    date: "(2020-Current)",
    icon: "ti-layout",
  },
  {
    title: "Real Estate Investor",
    subtitle: "D & E Homes | Cleveland, Ohio, USA",
    description:
      "Invest in value-add residential real estate across Cleveland market. Oversee all property acquisitions, renovations, leasing, and management.",
    date: "(2017-Current)",
    icon: "ti-home",
  },
  {
    title: "Director",
    subtitle: "X-Team | Melbourne, Victoria, Australia",
    description:
      "Led expansion of 100+ team of remote software engineers into Australian market. Scaled technology teams of Fortune 500's by placing software development staff on contract assignments.",
    date: "(2016-2018)",
    icon: "ti-stats-up",
  },
  {
    title: "Business Development",
    subtitle: "X-Five | Seattle, WA, USA",
    description:
      "Sold web development projects throughout the Seattle market. Worked alongside developers and product managers to bring client's projects to life.",
    date: "(2016-2018)",
    icon: "ti-money",
  },
  {
    title: "Bachelors in Business Management",
    subtitle: "Western Washington University | Bellingham, WA",
    description: "",
    date: "(2016-2018)",
    icon: "ti-crown",
  },
];

function Resume() {
  return (
    <section className="section section-resume section-resume-1">
      <div className="display-spacing">
        <header className="el-heading el-heading-center">
          <div className="el-icon">
            <h2 className="el-icon-title">
              <span>RESUME</span>
            </h2>
          </div>
          <h3>Experience & Skills</h3>
          <div className="divider divider-1-reverse divider-1-1"></div>
          <div className="divider divider-1-reverse divider-1-2"></div>
        </header>
        <Container className="mb-spacing">
          <Row>
            <Col md={6}>
              <div className="resume-row">
                <h4 className="resume-title">Experience</h4>
                <ul>
                  {experience.map((item, index) => (
                    <li key={index}>
                      <div className="resume-item">
                        <div className="resume-head">
                          <div className="el-icon">
                            <span
                              className={`el-icon-icon ${item.icon}`}
                            ></span>
                          </div>
                          <h5>{item.title}</h5>
                          <h6>{item.subtitle}</h6>
                        </div>
                        <div className="resume-body">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col md={6}>
              {/* <div className="resume-row">
                <h4 className="resume-title">Experience</h4>
                <ul>
                  {experience.map((item, index) => (
                    <li key={index}>
                      <div className="resume-item">
                        <div className="resume-head">
                          <div className="el-icon">
                            <span
                              className={`el-icon-icon ${item.icon}`}
                            ></span>
                          </div>
                          <h5>{item.title}</h5>
                          <h6>
                            {item.subtitle} <small>{item.date}</small>
                          </h6>
                        </div>
                        <div className="resume-body">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div> */}
              <h4 className="resume-title">Tech Stack</h4>

              <Container>
                <Row>
                  {skills.map((item, index) => (
                    <Col key={index} sm={12}>
                      <div className="skills-item">
                        <ProgressBar
                          label={item.title}
                          //   percent={item.percent}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        {/* <Container>
          <Row>
            {skills.map((item, index) => (
              <Col key={index} sm={6} xl={4}>
                <div className="skills-item">
                  <ProgressBar label={item.title} percent={item.percent} />
                </div>
              </Col>
            ))}
          </Row>
        </Container> */}
      </div>
    </section>
  );
}

export default Resume;
