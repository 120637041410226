import React from "react";
import { Container, Hidden, Row, Col } from "react-grid-system";

function About() {
  return (
    <section className="section section-about section-about-1">
      <div className="display-spacing">
        <Container>
          <Row>
            <Hidden xs sm md>
              <Col lg={5}>
                <div className="about-image">
                  <div
                    className="image overlay-image"
                    style={{
                      backgroundImage: `url(/assets/images/about/ryan.jpeg)`,
                    }}
                  ></div>
                </div>
              </Col>
            </Hidden>
            <Col lg={7}>
              <div className="about-content">
                <header className="el-heading">
                  <h2 className="el-icon">
                    <span className="el-icon-title">ABOUT ME</span>
                  </h2>
                  <h3>
                    <span>Hello, I am Ryan Evans.</span>
                  </h3>
                  <div className="divider divider-1"></div>
                  <p>
                    I'm a business guy who took a turn and went into web
                    development. After years of working around the world
                    consulting for startups and other tech companies, I
                    developed an unshakeable need to know how to build stuff
                    myself, so I learned to code.
                  </p>
                </header>
                <Row className="pt-30">
                  <Col xs={12} sm={12} md={6}>
                    <div className="el-card-icon">
                      <div className="el-icon">
                        <span className="el-icon-icon ti-layout"></span>
                      </div>
                      <div className="content">
                        <h5>Software Development</h5>
                        <p>
                          I excel in front end technologies and frameworks such
                          as JavaScript and React.JS.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <div className="el-card-icon">
                      <div className="el-icon">
                        <span className="el-icon-icon ti-money"></span>
                      </div>
                      <div className="content">
                        <h5>Software Sales</h5>
                        <p>
                          I spent years selling websites and apps so I'm a great
                          translator between business people, developers, and
                          clients.{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <div className="el-card-icon">
                      <div className="el-icon">
                        <span className="el-icon-icon ti-stats-up"></span>
                      </div>
                      <div className="content">
                        <h5>Business Consulting</h5>
                        <p>
                          A lot of my career has been spent helping companies
                          optimize sales pipelines and operations. I'll always
                          find a way to make things run a little bit smoother.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <div className="el-card-icon">
                      <div className="el-icon">
                        <span className="el-icon-icon ti-thought"></span>
                      </div>
                      <div className="content">
                        <h5>Problem Solving</h5>
                        <p>
                          I'm the 'figure it out' guy who's launched companies
                          into multiple countries. I like to embrace the
                          unknown.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <div className="el-card-icon">
                      <div className="el-icon">
                        <span className="el-icon-icon ti-home"></span>
                      </div>
                      <div className="content">
                        <h5>Real Estate Investing</h5>
                        <p>
                          I built a real estate investing and renovation
                          business for fun. Turns out it's not fun as I'd hoped,
                          but it's just my side hustle.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={6}>
                    <div className="el-card-icon">
                      <div className="el-icon">
                        <span className="el-icon-icon ti-direction-alt"></span>
                      </div>
                      <div className="content">
                        <h5>Adapting</h5>
                        <p>
                          How many people do you know who can build you a
                          website and remodel your kitchen in under week? At
                          least one!
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default About;
